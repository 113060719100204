import React, { useEffect, useState } from "react"
import axios from "../../http/axios"

import { openNavFilters } from "../../scripts/sideNavMoreFilters"

import HeartIcon from "../../svg/heart-icon-dark.inline.svg"
import DeleteIcon from "../../svg/heart-icon-red.inline.svg"

import AppMap from "../map/AppMap"
import { addSpaceNumberRegEx, changeTitleRout } from "../../scripts/Utils"

import { useCurrentUser } from "../../components/CurrentUserContext"

import { Link } from "gatsby"
import { useTranslation } from "react-i18next"

import "rc-slider/assets/index.css"
import { SCHOOL_MODES } from "../../constants/schoolModes"

const Slider = require("rc-slider")
const createSliderWithTooltip = Slider.createSliderWithTooltip
const Range = createSliderWithTooltip(Slider.Range)

const SearchResultsMapContentSection = props => {
  const { currentUser } = useCurrentUser()

  const [schools, setSchools] = useState([])

  const { t, i18n } = useTranslation()
  const currentLang = i18n.language

  const w = "30vw"
  const h = "50vw"

  const schoolNameFilterValue = props.filterState.name

  useEffect(() => {
    setSchools(props.items)
  }, [props.items])

  const handleClick = e => {
    const { name, value } = e.target

    let temporaryFilterState = { ...props.filterState }

    if (
      value === t("main.header.types") ||
      value === t("main.header.programmes") ||
      value === t("main.header.locations")
    ) {
      let propertyToChange = temporaryFilterState[name]
      propertyToChange.forEach(el => {
        el.isChecked = false
      })
    } else {
      let propertyToChange = temporaryFilterState[name]
      propertyToChange.forEach(el => {
        el.isChecked = el.value === value
      })
    }

    props.updateFilterState(temporaryFilterState)
    props.filterSchools(temporaryFilterState)
  }

  const handleChange = e => {
    const { name, value } = e.target

    let temporaryFilterState = { ...props.filterState }

    if (
      value === t("main.header.types") ||
      value === t("main.header.programmes") ||
      value === t("main.header.locations")
    ) {
      let propertyToChange = temporaryFilterState[name]
      propertyToChange.forEach(el => {
        el.isChecked = false
      })
    } else {
      let propertyToChange = temporaryFilterState[name]
      propertyToChange.forEach(el => {
        el.isChecked = el.value === value
      })
    }

    props.updateFilterState(temporaryFilterState)
    props.filterSchools(temporaryFilterState)
  }

  //--------ADD TO FAVOURITES
  const addToFavourites = school => {
    if (currentUser) {
      axios
        .post(`/accounts/${currentUser.id}/saved-schools`, {
          accountId: currentUser.id,
          schoolId: school.id,
          schoolMode: 0,
        })
        .then(() => {
          const currentSchools = [...schools]
          const prevSchool = currentSchools.find(x => x.id === school.id)
          prevSchool.isFavorite = true
          setSchools(currentSchools)
        })
        .catch()
    }
  }

  const addToFavouritesSummer = school => {
    if (currentUser) {
      axios
        .post(`/accounts/${currentUser.id}/saved-summer-schools`, {
          accountId: currentUser.id,
          schoolId: school.id,
          schoolMode: 0,
        })
        .then(() => {
          const currentSummerSchools = [...schools]
          const prevSummerSchool = currentSummerSchools.find(
            x => x.id === school.id
          )
          prevSummerSchool.isFavorite = true
          setSchools(currentSummerSchools)
        })
        .catch()
    }
  }

  const removeFromFavourites = school => {
    if (currentUser) {
      axios
        .delete(`/accounts/${currentUser.id}/saved-schools/${school.id}`)
        .then(() => {
          const currentSchools = [...schools]
          const prevSchool = currentSchools.find(x => x.id === school.id)
          prevSchool.isFavorite = false
          setSchools(currentSchools)
        })
        .catch()
    }
  }

  const removeFromFavouritesSummer = school => {
    if (currentUser) {
      axios
        .delete(`/accounts/${currentUser.id}/saved-summer-schools/${school.id}`)
        .then(() => {
          const currentSummerSchools = [...schools]
          const prevSummerSchool = currentSummerSchools.find(
            x => x.id === school.id
          )
          prevSummerSchool.isFavorite = false
          setSchools(currentSummerSchools)
        })
        .catch()
    }
  }

  function resolveFavouriteSchools(item) {
    if (item.isFavorite) {
      return (
        <DeleteIcon
          className="curs-P"
          onClick={() => {
            removeFromFavourites(item)
          }}
        />
      )
    } else {
      return (
        <HeartIcon
          className="heart-icon-dark curs-P"
          onClick={() => {
            addToFavourites(item)
          }}
        />
      )
    }
  }

  function resolveFavouriteSummerSchools(item) {
    if (item.isFavorite) {
      return (
        <DeleteIcon
          className="curs-P"
          onClick={() => {
            removeFromFavouritesSummer(item)
          }}
        />
      )
    } else {
      return (
        <HeartIcon
          className="heart-icon-dark curs-P"
          onClick={() => {
            addToFavouritesSummer(item)
          }}
        />
      )
    }
  }

  const [firstPriceDisplay, setFirstPriceDisplay] = useState(false)

  const firstPrice = firstPriceDisplay ? "english-first-price" : ""

  useEffect(() => {
    setFirstPriceDisplay(currentLang === "en")
  })

  function resolveSchoolFees(item) {
    return resolveSchoolFeesHandler(item, "perTermMin", "perTermMax")
  }

  function resolveSummerSchoolFees(item) {
    return resolveSchoolFeesHandler(item, "weeklyFeeMin", "weeklyFeeMax")
  }

  function resolveSchoolFeesHandler(item, min, max) {
    if (item[min] !== item[max] && item[min] != null && item[max] != null) {
      return (
        <div className={`saved-school-price ${firstPrice}`}>
          £{addSpaceNumberRegEx(item[min])} – {addSpaceNumberRegEx(item[max])}
        </div>
      )
    } else {
      return (
        <div className={`saved-school-price ${firstPrice}`}>
          £{addSpaceNumberRegEx(item[min] || item[max])}
        </div>
      )
    }
  }

  const setSelectionInitialValue = (filterPropertyName, defaultValue) => {
    let selectedValues = props.filterState[filterPropertyName]?.filter(
      el => el.isChecked
    )

    if (selectedValues && selectedValues.length === 1) {
      return selectedValues[0].value
    }
    return t(defaultValue)
  }

  const resolveNoDataText = () => {
    if (Number(props.schoolMode) === SCHOOL_MODES.SCHOOL) {
      return t("search.noDataBoarding")
    } else {
      return t("search.noDataSummer")
    }
  }

  const resolveFoundSchoolsText = () => {
    if (Number(props.schoolMode) === SCHOOL_MODES.SCHOOL) {
      return t("search.additionalResultsSummer") + props.items.length
    } else {
      return t("search.additionalResultsBoarding") + props.items.length
    }
  }

  const resolveOtherSchoolFoundLink = () => {
    if (Number(props.schoolMode) === SCHOOL_MODES.SCHOOL) {
      return (
        <a
          href={`/${i18n.language}/result-map-page?schoolOrganisationTypes=&searchInput=${schoolNameFilterValue}`}
          target="_blank"
        >
          <div className="results-amount no-data-title show-other-found-schools-btn curs-P">
            {t("search.showAddResults")}
          </div>
        </a>
      )
    } else {
      return (
        <a
          href={`/${i18n.language}/result-map-page?schoolTypes=&searchInput=${schoolNameFilterValue}`}
          target="_blank"
        >
          <div className="results-amount no-data-title show-other-found-schools-btn curs-P">
            {t("search.showAddResults")}
          </div>
        </a>
      )
    }
  }

  schools.sort((a, b) => a.name?.localeCompare(b.name))

  const processEdProgrammes = item => {
    let arr
    if (item && item["educationalPrograms"]) {
      arr = item["educationalPrograms"]?.split(", ")
      arr.splice(0, 3)
    }
    return arr.length
  }

  function getSchoolTypesSelectedAnyStatus() {
    return Number(props.schoolMode) === SCHOOL_MODES.SCHOOL
      ? props.filterState.schoolTypes?.filter(el => el.isChecked).length
      : props.filterState.schoolOrganisationTypes?.filter(el => el.isChecked)
          .length
  }

  function getSchoolTypesDependsOnMode() {
    return Number(props.schoolMode) === SCHOOL_MODES.SCHOOL
      ? props.filterState.schoolTypes
      : props.filterState.schoolOrganisationTypes
  }

  return (
    <div className="search-results-content">
      <div className="compare-map-wrapper container">
        {/* <!--- TITLE ---> */}
        <div className="compare-titles">
          <div className="row mt-4">
            <div className="title-search d-flex align-items-center gap-4 col-12">
              {Number(props.schoolMode) === SCHOOL_MODES.SUMMER_SCHOOL ? (
                <div className="boarding-school-results-title">
                  {t("main.header.summerSchools")}
                </div>
              ) : (
                <div className="boarding-school-results-title">
                  {t("search.schools")}
                </div>
              )}
            </div>
          </div>
          <div className="row">
            <div className="results-amount col-12">
              {t("search.schoolFound")} {schools?.length}
            </div>
          </div>

          <div className="search-filters-wrapper row f-js f-ac">
            <div id="search-block" className="search-block col-lg-5">
              <form className="row">
                {/* SELECT TYPES */}
                {getSchoolTypeSelector()}

                {/* SELECT PROGRAM */}
                {getEducationalProgramSelector()}
                {/* SELECT LOCATION */}
                {getSettlementTypesSelector()}
                {/* SELECT STUDENT AGE RANGE */}
                {getStudentAgeRangeSelector()}
                {/* MORE FILTERS */}
                {getMoreFiltersSelector()}
              </form>
            </div>
          </div>
        </div>

        {/* <!--- SCHOOL ITEMS LIST ---> */}
        {getSchoolItemsResult()}
      </div>
    </div>
  )

  function getSchoolTypeSelector() {
    return (
      <div
        className={`search-select-types-wrapper ${
          getSchoolTypesSelectedAnyStatus() ? "bordered-selector" : ""
        } my-3 col-md-3 col-lg-3`}
      >
        <div id="select-types" className="custom-select-wrapper">
          <div className="custom-select">
            <select
              id="categoryId1"
              value={setSelectionInitialValue(
                Number(props.schoolMode) === SCHOOL_MODES.SCHOOL
                  ? "schoolTypes"
                  : "schoolOrganisationTypes",
                "main.header.types"
              )}
              name={
                Number(props.schoolMode) === SCHOOL_MODES.SUMMER_SCHOOL
                  ? "schoolOrganisationTypes"
                  : "schoolTypes"
              }
              onChange={handleChange}
              className="contactSelect"
            >
              <option>{t("main.header.types")}</option>
              {getSchoolTypesDependsOnMode()?.map(selectOptionMapper())}
            </select>
          </div>
        </div>
      </div>
    )
  }

  function selectOptionMapper() {
    return item => {
      return (
        <option key={item.id} defaultValue={item.value}>
          {item.value}
        </option>
      )
    }
  }

  function getEducationalProgramSelector() {
    return (
      <div
        className={`search-select-programmes-wrapper ${
          props.filterState.educationalProgrammes?.filter(el => el.isChecked)
            .length
            ? "bordered-selector"
            : ""
        } my-3 col-md-3 col-lg-3`}
      >
        <div id="select-programmes" className="custom-select-wrapper">
          <div className="custom-select">
            <select
              id="categoryId3"
              name="educationalProgrammes"
              value={setSelectionInitialValue(
                "educationalProgrammes",
                "main.header.programmes"
              )}
              onChange={el => {
                handleChange(el)
              }}
              className="contactSelect"
            >
              <option>{t("main.header.programmes")}</option>
              {props.filterState.educationalProgrammes.map(item => {
                return (
                  <option key={item.id} value={item.value}>
                    {item.value}
                  </option>
                )
              })}
            </select>
          </div>
        </div>
      </div>
    )
  }

  function getSettlementTypesSelector() {
    return (
      <>
        {Number(props.schoolMode) === SCHOOL_MODES.SCHOOL && (
          <div
            className={`my-3 col-md-3 col-lg-3 ${
              props.filterState.settlementTypes?.filter(el => el.isChecked)
                .length
                ? "bordered-selector"
                : ""
            }`}
          >
            <div id="select-location" className="custom-select-wrapper">
              <div className="custom-select">
                <select
                  id="categoryId2"
                  name="settlementTypes"
                  value={setSelectionInitialValue(
                    "settlementTypes",
                    "main.header.locations"
                  )}
                  onChange={handleChange}
                  className="contactSelect"
                >
                  <option>{t("main.header.locations")}</option>
                  {props.filterState.settlementTypes?.map(selectOptionMapper())}
                </select>
              </div>
            </div>
          </div>
        )}
      </>
    )
  }

  function getStudentAgeRangeSelector() {
    return (
      <>
        {Number(props.schoolMode) === SCHOOL_MODES.SUMMER_SCHOOL && (
          <div className="school-data-section summer-schools-search-filters range-slider my-3 col-md-5 col-lg-5">
            <div className="school-data-section-title text-center">
              {t("search.studentsAge")}
            </div>
            <Range
              value={[
                props.filterState.studentAgeMin
                  ? props.filterState.studentAgeMin
                  : 3,
                props.filterState.studentAgeMax
                  ? props.filterState.studentAgeMax
                  : 19,
              ]}
              min={3}
              step={1}
              max={19}
              onChange={value => {
                let temporaryFilterState = { ...props.filterState }

                if (value[0] === 3 && value[1] === 19) {
                  temporaryFilterState.studentAgeMin = ""
                  temporaryFilterState.studentAgeMax = ""
                } else {
                  temporaryFilterState.studentAgeMin = value[0]
                  temporaryFilterState.studentAgeMax = value[1]
                }

                props.updateFilterState(temporaryFilterState)
              }}
              onAfterChange={() => {
                props.filterSchools()
              }}
            />
          </div>
        )}
      </>
    )
  }

  function getMoreFiltersSelector() {
    return (
      <>
        {Number(props.schoolMode) === SCHOOL_MODES.SCHOOL && (
          <div className="my-3 col-md-2 col-lg-3">
            <div
              className="custom-select more-filters"
              onClick={openNavFilters}
            >
              <div className="custom-select__trigger text-center">
                {t("search.moreFilters")}
              </div>
            </div>
          </div>
        )}
      </>
    )
  }

  function getSchoolItemsResult() {
    return (
      <div className="location-comparison-content-wrapper row">
        <div className="school-items-map-view-wrapper col-md-12 col-lg-6">
          {schools.length !== 0 ? (
            <>
              {schools &&
                schools.map(item => {
                  return (
                    <div
                      key={item.id}
                      className="school-item-comparison-wrapper f-jb"
                    >
                      {!item.isSummer ? (
                        <Link
                          to={`/${currentLang}/school/${changeTitleRout(
                            item["engName"]
                          )}`}
                        >
                          <div className="school-item-comparison-image-description gap-3 f-js">
                            <div className="school-comparison-image-wrapper f-ac">
                              <img
                                src={item.resolvedImageUrl}
                                alt=""
                                className="school-comparison-image"
                              />
                            </div>
                            <div className="school-comparison-main-info">
                              <div>
                                <div className="saved-school-text d-flex align-baseline">
                                  <span
                                    title={item.schoolTypes}
                                    className="school-type-inline-element"
                                  >
                                    {item.schoolTypes}
                                  </span>
                                  &nbsp;·&nbsp;
                                  <span className="price-inline-element">
                                    {item.ageMin}–{item.ageMax}{" "}
                                    {t("school.years")}
                                  </span>
                                </div>
                                <div className="saved-school-title">
                                  {item.name}
                                </div>
                                <div className="saved-school-text">
                                  {item.region} · {item.settlement}
                                </div>
                              </div>
                              {getEducationalProgramsElement(item)}
                            </div>
                          </div>
                        </Link>
                      ) : (
                        <Link
                          to={`/${currentLang}/summer-schools/${changeTitleRout(
                            item["engName"]
                          )}`}
                        >
                          <div className="school-item-comparison-image-description gap-3 f-js">
                            <div className="school-comparison-image-wrapper f-ac">
                              <img
                                src={item.resolvedImageUrl}
                                alt=""
                                className="school-comparison-image"
                              />
                            </div>
                            <div className="school-comparison-main-info">
                              <div>
                                <div className="saved-school-text d-flex align-baseline">
                                  <span
                                    title={item.schoolOrganisationTypes}
                                    className="school-type-inline-element"
                                  >
                                    {item.schoolOrganisationTypes}
                                  </span>
                                  &nbsp;·&nbsp;
                                  <span className="price-inline-element">
                                    {item.ageMin}–{item.ageMax}{" "}
                                    {t("school.years")}
                                  </span>
                                </div>
                                <div className="saved-school-title">
                                  {item.name}
                                </div>
                                <div className="saved-school-text">
                                  {t("school.header.NumberStudyCentres")}:{" "}
                                  {item.studyCentersNumber}
                                </div>
                              </div>
                              {getEducationalProgramsElement(item)}
                            </div>
                          </div>
                        </Link>
                      )}
                      <div className="school-comparison-price price1">
                        {currentUser && (
                          <>
                            {!item.isSummer
                              ? resolveFavouriteSchools(item)
                              : resolveFavouriteSummerSchools(item)}
                          </>
                        )}

                        {!item.isSummer
                          ? resolveSchoolFees(item)
                          : resolveSummerSchoolFees(item)}
                      </div>
                    </div>
                  )
                })}
            </>
          ) : (
            <>
              <div className="user-profile-title no-data-title">
                {resolveNoDataText()}
              </div>
              {props.items.length > 0 && (
                <div className="show-other-found-schools-wrapper">
                  <div className="results-amount no-data-title">
                    {t("search.additionalResults")}
                    {resolveFoundSchoolsText()}
                  </div>
                  {resolveOtherSchoolFoundLink()}
                </div>
              )}
              {/*TODO : DON`T KNOW WHAT IS THIS*/}
              {/*<div className="school-item-comparison-wrapper no-data-case f-jb">*/}
              {/*  <div className="school-item-comparison-image-description f-js">*/}
              {/*    <div className="school-comparison-image-wrapper f-ac"></div>*/}
              {/*    <div className="school-comparison-main-info">*/}
              {/*      <div className="saved-school-text"></div>*/}
              {/*      <div className="saved-school-title"></div>*/}
              {/*      <div className="saved-school-text"></div>*/}
              {/*      <div className="saved-school-text school-levels"></div>*/}
              {/*    </div>*/}
              {/*  </div>*/}

              {/*  <div className="school-comparison-price price1"></div>*/}
              {/*</div>*/}
            </>
          )}
        </div>
        {/* <!-- MAP FRAME --> */}
        <div className="map-view-wrapper col-lg-6">
          <div className="map-frame-wrapper d-flex justify-content-center">
            <AppMap
              width={w}
              height={h}
              items={[schools]}
              isSearchResultsPage={true}
            />
          </div>
        </div>
      </div>
    )
  }

  function getEducationalProgramsElement(item) {
    return (
      <div className="saved-school-text school-levels">
        {currentLang === "ru" || currentLang === "uk" ? (
          <>{educationalProgramsUkRu(item)}</>
        ) : (
          <>{educationalProgramsEn(item)}</>
        )}
      </div>
    )
  }

  function educationalProgramsUkRu(item) {
    return item["educationalPrograms"] &&
      item["educationalPrograms"].split(", ").length > 3
      ? item["educationalPrograms"].split(", ")[0] +
          ", " +
          item["educationalPrograms"].split(", ")[1] +
          ", " +
          item["educationalPrograms"].split(", ")[2] +
          " " +
          t("search.andMore1") +
          " " +
          t("search.andMore2") +
          " " +
          processEdProgrammes(item)
      : item["educationalPrograms"]
  }

  function educationalProgramsEn(item) {
    return item["educationalPrograms"] &&
      item["educationalPrograms"].split(", ").length > 3
      ? item["educationalPrograms"].split(", ")[0] +
          ", " +
          item["educationalPrograms"].split(", ")[1] +
          ", " +
          item["educationalPrograms"].split(", ")[2] +
          " " +
          t("search.andMore1") +
          " " +
          item["educationalPrograms"].split(", ").splice(0, 3).length +
          " " +
          t("search.andMore2")
      : item["educationalPrograms"]
  }
}

export default SearchResultsMapContentSection
