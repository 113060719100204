 //More filters school type
 export function openNavFilters() {
	document.getElementById("side-nav-filters-school-type-content").style.display = "block";
  
  }
  
  export function closeNavFilters(){
    let navFilter = document.getElementById("side-nav-filters-school-type-content")
    if(navFilter){
      navFilter.style.display = "none";
    }
  }

//More filters students
  export function openNavFiltersStudents() {
  document.getElementById("side-nav-filters-school-type-content").style.display = "none";
  document.getElementById("side-nav-filters-fees-content").style.display = "none";
  document.getElementById("side-nav-filters-subjects-content").style.display = "none";
  document.getElementById("side-nav-filters-students-content").style.display = "block";
  }
    
  export function closeNavFiltersStudents(){
  document.getElementById("side-nav-filters-students-content").style.display = "none";
  }

//More filters fees
export function openNavFiltersFees() {
  document.getElementById("side-nav-filters-school-type-content").style.display = "none";
  document.getElementById("side-nav-filters-students-content").style.display = "none";
  document.getElementById("side-nav-filters-subjects-content").style.display = "none";
  document.getElementById("side-nav-filters-fees-content").style.display = "block";
  }
      
  export function closeNavFiltersFees(){
  document.getElementById("side-nav-filters-fees-content").style.display = "none";
  }
  

//More filters subjects
export function openNavFiltersSubjects() {
  document.getElementById("side-nav-filters-school-type-content").style.display = "none";
  document.getElementById("side-nav-filters-students-content").style.display = "none";
  document.getElementById("side-nav-filters-fees-content").style.display = "none";
  document.getElementById("side-nav-filters-subjects-content").style.display = "block";
  }
      
  export function closeNavFiltersSubjects(){
  document.getElementById("side-nav-filters-subjects-content").style.display = "none";
  }
