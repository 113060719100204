import React from "react"
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api"
import { useTranslation } from "react-i18next"

import { GOOGLE_MAP_KEY, GOOGLE_MAP_ID } from "../../constants/constants"

import { isBrowser } from "../../components/CurrentUserContext"

const libraries = ["places", "drawing"]

const AppMap = props => {
  const { width, height } = props
  const { i18n } = useTranslation()
  const currentLang = i18n.language

  const isSchoolPage = props.isSchoolPage

  const items = props.items

  let allStudyCenters = []

  if (items[0][0] && items[0][0].isSummer) {
    items[0].forEach(item => {
      if (item.studyCenters) {
        for (let i = 0; i < item.studyCenters.length; ++i) {
          allStudyCenters.push(item.studyCenters[i])
        }
      }
    })
  } else {
    if (items[0].isSummer) {
      if (items[0].hasOwnProperty("dataContext")) {
        for (let studyCenter of items[0].dataContext.scholarships) {
          allStudyCenters.push(studyCenter)
        }
      } else {
        items.forEach(item => {
          for (let studyCenter of item.studyCenters) {
            allStudyCenters.push(studyCenter)
          }
        })
      }
    }
  }

  const key = GOOGLE_MAP_KEY
  const containerStyle = {
    width: width,
    height: height,
  }

  const mappedItemsPlace = Array.isArray(items[0])
    ? items[0].map(el => {
      return {
        lat: el.latitude || null,
        lng: el.longitude || null,
        title: el.name,
      }
    })
    : {
      lat: items[0].latitude || null,
      lng: items[0].longitude || null,
      title: items[0].name,
    }

  const mappedItemsAirport =
    items[0].length > 1
      ? items[0].map(el => {
        return {
          lat: Number(el.transportStationLongitude),
          lng: Number(el.transportStationLatitude),
        }
      })
      : items.map(airport => {
        return {
          lat: Number(airport.transportStationLongitude),
          lng: Number(airport.transportStationLatitude),
        }
      })

  function resolveStudyCentresTitle(el) {
    if (!items?.length) {
      return ""
    }
    if (!Array.isArray(items[0])) {
      return ""
    }
    for (let summerSchool of items[0]) {
      if (summerSchool.studyCenters.includes(el)) {
        return summerSchool.name
      }
    }
  }

  let mappedStudyCentres


  if (items[0].isSummer || items[0][0]?.isSummer) {
    mappedStudyCentres = allStudyCenters?.map(el => {
      return {
        lat: el.longitude,
        lng: el.latitude,
        title: items[0].name || resolveStudyCentresTitle(el),
        name: el.name
      }
    })
  }

  const changeMapLabelsForUkrainian = () => {
    if (currentLang === "uk" && isBrowser()) {
      const labelsToChange = document.querySelectorAll(".gm-style-mtc li")
      if (labelsToChange[0] != null && labelsToChange[1] != null) {
        labelsToChange[0].querySelector("label").innerText = "Рельєф"
        labelsToChange[1].querySelector("label").innerText = "Назви об’єктів"
      }
    }
  }

  changeMapLabelsForUkrainian()


  return (
    <LoadScript
      googleMapsApiKey={key}
      libraries
      language={i18n.language}
      mapIds={[GOOGLE_MAP_ID]}
    >

      <GoogleMap
        mapContainerStyle={containerStyle}
        center={props.isSearchResultsPage ?
          {
            lat: 53.87771106253311,
            lng: -3.4158797662633362,
          }
          :
          {
            lat: 53.50431422947225,
            lng: -2.9172701951083275,
          }}
        zoom={6}
        options={{ mapId: "11010249d7ea51b3" }}
        mapContainerClassName="my-google-map-wrapper"
      >
        {Array.isArray(mappedItemsPlace) && !items[0].isSummer ? (
          mappedItemsPlace?.filter(el => el.lat && el.lng)
          .map((el, index) => (
            <Marker
              key={index}
              icon={el.icon || null}
              position={el}
              title={el.title}
            />
          ))
        ) : (
          <Marker
            key={1}
            icon={mappedItemsPlace.icon || null}
            position={mappedItemsPlace}
            title={mappedItemsPlace.title}
          />
        )}

        {mappedItemsAirport?.length &&
          mappedItemsAirport?.filter(airport => airport.lat && airport.lng)
          .map((airport, index) => (
            <Marker
              key={index}
              icon={airport.icon || null}
              position={airport}
            />
          ))}

        {mappedStudyCentres?.length &&
          mappedStudyCentres?.filter(studyCenter => studyCenter.lat && studyCenter.lng)
          .map((studyCent, index) => (
            <Marker
              key={index}
              icon={studyCent.icon || null}
              position={studyCent}
              title={isSchoolPage ? studyCent.name : studyCent.title}
            />
          ))}
      </GoogleMap>
    </LoadScript>
  )
}

export default React.memo(AppMap)
