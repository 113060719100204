import React from "react"
import { Tab, TabList, TabPanel, Tabs } from "react-tabs"
import { closeNavFilters } from "../../scripts/sideNavMoreFilters"
import Accordion from "@material-ui/core/Accordion"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import XButtonIcon from "../../svg/x-button.inline.svg"
import Switch from "@material-ui/core/Switch"
import PlusIcon from "../../svg/plus-icon.inline.svg"
import { useTranslation } from "react-i18next"
import { isBrowser } from "../../components/CurrentUserContext"
import "rc-slider/assets/index.css"

const Slider = require("rc-slider")
const createSliderWithTooltip = Slider.createSliderWithTooltip
const Range = createSliderWithTooltip(Slider.Range)

const MoreFilters = props => {
  const { t } = useTranslation()

  //-----SUBMIT FILTERS HANDLING
  function handleSubmit(e) {
    e.preventDefault()
    closeNavFilters()
    props.filterSchools()
  }

  //------SUBJECTS HANDLING

  const handleAddClick = () => {
    let temporarySubjects = [...props.filterState.subjects]
    temporarySubjects.push({ subjectName: "" })
    props.updateFilterState({
      ...props.filterState,
      subjects: temporarySubjects,
    })
  }

  const handleRemoveClick = index => {
    let temporarySubjects = [...props.filterState.subjects]
    temporarySubjects.splice(index, 1)
    props.updateFilterState({
      ...props.filterState,
      subjects: temporarySubjects,
    })
  }

  const handleInputChangeSubjects = (el, index) => {
    let temporarySubjects = props.filterState.subjects
    temporarySubjects[index].subjectName = el.target.value
    props.updateFilterState({
      ...props.filterState,
      subjects: temporarySubjects,
    })
  }

  const clearFilters = () => {
    if (isBrowser()) {
      closeNavFilters()
      props.clearFilters()
    }
  }

  //------SELECTIONS HANDLING

  function onChangeSchoolSelectionProperty(el, selectionProperty) {
    let selectionPropertyValue = [...props.filterState[selectionProperty]]
    let changedType = selectionPropertyValue.find(
      type => type.value === el.target.value
    )
    if (changedType != null) {
      let temporaryFilterState = { ...props.filterState }
      changedType.isChecked = !changedType.isChecked
      temporaryFilterState[selectionProperty] = selectionPropertyValue

      props.updateFilterState(temporaryFilterState)
    }
  }

  //------RANGES HANDLING

  function onChangeStudentsAge(el, defaultMin, defaultMax) {
    onChangeRangeDefault(
      "studentAgeMin",
      "studentAgeMax",
      el,
      defaultMin,
      defaultMax
    )
  }

  function onChangeBoardersAge(el, defaultMin, defaultMax) {
    onChangeRangeDefault(
      "boardersAgeMin",
      "boardersAgeMax",
      el,
      defaultMin,
      defaultMax
    )
  }

  function onChangeStudentsAmount(el, defaultMin, defaultMax) {
    onChangeRangeDefault(
      "studentsAmountMin",
      "studentsAmountMax",
      el,
      defaultMin,
      defaultMax
    )
  }

  function onChangeBoardersPercent(el, defaultMin, defaultMax) {
    onChangeRangeDefault(
      "boardersPercentMin",
      "boardersPercentMax",
      el,
      defaultMin,
      defaultMax
    )
  }

  function onChangeInternationalStudentsPercent(el, defaultMin, defaultMax) {
    onChangeRangeDefault(
      "internationalStudentsPercentMin",
      "internationalStudentsPercentMax",
      el,
      defaultMin,
      defaultMax
    )
  }

  function onChangeTeacherToStudentsRatio(el, defaultMin, defaultMax) {
    onChangeRangeDefault(
      "teacherToStudentsRatioMin",
      "teacherToStudentsRatioMax",
      el,
      defaultMin,
      defaultMax
    )
  }

  function onChangeFeePerTerm(el, defaultMin, defaultMax) {
    onChangeRangeDefault(
      "feePerTermMin",
      "feePerTermMax",
      el,
      defaultMin,
      defaultMax
    )
  }

  function onChangeDeposit(el, defaultMin, defaultMax) {
    onChangeRangeDefault("depositMin", "depositMax", el, defaultMin, defaultMax)
  }

  function handleSwitchChange() {
    props.updateFilterState({
      ...props.filterState,
      scholarshipsAvailable: !props.filterState.scholarshipsAvailable,
    })
  }

  function setRangeOrDefault(propertyName, defaultValue) {
    return props.filterState[propertyName]
      ? props.filterState[propertyName]
      : defaultValue
  }

  function onChangeRangeDefault(
    paramNameMin,
    paramNameMax,
    el,
    defaultMin,
    defaultMax
  ) {
    let temporaryFilterState = { ...props.filterState }
    if (el[0] === defaultMin && el[1] === defaultMax) {
      temporaryFilterState[paramNameMin] = ""
      temporaryFilterState[paramNameMax] = ""
    } else {
      temporaryFilterState[paramNameMin] = el[0]
      temporaryFilterState[paramNameMax] = el[1]
    }
    props.updateFilterState(temporaryFilterState)
  }

  return (
    <div id="side-nav-filters-school-type-content" className="sidenav-filters">
      <form onSubmit={handleSubmit}>
        {/* <!--- SIDE NAV HEADER ---> */}
        <div className="more-filters-header-wrapper">
          {/* <!---- CLOSE BUTTON ----> */}
          <div
            className="sign-up-btn f-ac"
            onClick={() => {
              closeNavFilters()
              props.filterSchools()
            }}
          >
            <div className="x-button-wrapper f-ac f-jc curs-P">
              <XButtonIcon className="x-button" />
            </div>
            <div className="sign-up-text">{t("search.moreFilters")}</div>
          </div>
          <Tabs forceRenderTabPanel={true}>
            <TabList>
              <Tab>{t("search.schoolType")}</Tab>
              <Tab>{t("search.studentsTab")}</Tab>
              <Tab>{t("search.fees")}</Tab>
              <Tab>{t("search.subjects")}</Tab>
            </TabList>

            <TabPanel>
              <div className="side-nav-filters-tab-wrapper">
                <div className="side-nav-content-wrapper">
                  {getSelectionFilterBlock(
                    "schoolTypes",
                    "search.educationLvl"
                  )}
                  {getRangeFilterBlock(
                    "search.studentsAge",
                    3,
                    19,
                    1,
                    [
                      setRangeOrDefault("studentAgeMin", 3),
                      setRangeOrDefault("studentAgeMax", 19),
                    ],
                    onChangeStudentsAge
                  )}
                  {getRangeFilterBlock(
                    "search.boardersAge",
                    3,
                    19,
                    1,
                    [
                      setRangeOrDefault("boardersAgeMin", 3),
                      setRangeOrDefault("boardersAgeMax", 19),
                    ],
                    onChangeBoardersAge
                  )}
                  {getSelectionFilterBlock("regions", "search.regions")}
                  {getSelectionFilterBlock(
                    "educationalProgrammes",
                    "search.edprograms"
                  )}
                  {getSelectionFilterBlock("religions", "search.religion")}
                  {getSelectionFilterBlock(
                    "settlementTypes",
                    "search.location"
                  )}
                  {getSelectionFilterBlock(
                    "transportStations",
                    "search.nearestAirports"
                  )}
                </div>
              </div>
            </TabPanel>
            <TabPanel>
              <div className="side-nav-filters-tab-wrapper">
                <div className="side-nav-content-wrapper">
                  {getRangeFilterBlock(
                    "search.students",
                    1,
                    2000,
                    1,
                    [
                      setRangeOrDefault("studentsAmountMin", 1),
                      setRangeOrDefault("studentsAmountMax", 2000),
                    ],
                    onChangeStudentsAmount
                  )}
                  {getRangeFilterBlock(
                    "search.boarders",
                    0,
                    100,
                    1,
                    [
                      setRangeOrDefault("boardersPercentMin", 0),
                      setRangeOrDefault("boardersPercentMax", 100),
                    ],
                    onChangeBoardersPercent
                  )}
                  {getRangeFilterBlock(
                    "search.intStudents",
                    1,
                    30,
                    1,
                    [
                      setRangeOrDefault(
                        "internationalStudentsPercentMin",
                        props.filterState.internationalStudentsPercentMin || 1
                      ),
                      setRangeOrDefault(
                        "internationalStudentsPercentMax",
                        props.filterState.internationalStudentsPercentMax || 30
                      ),
                    ],
                    onChangeInternationalStudentsPercent
                  )}
                  {getRangeFilterBlock(
                    "search.intStudentsRatio",
                    1,
                    20,
                    1,
                    [
                      setRangeOrDefault(
                        "teacherToStudentsRatioMin",
                        props.filterState.teacherToStudentsRatioMin || 1
                      ),
                      setRangeOrDefault(
                        "teacherToStudentsRatioMax",
                        props.filterState.teacherToStudentsRatioMax || 30
                      ),
                    ],
                    onChangeTeacherToStudentsRatio
                  )}
                </div>
              </div>
            </TabPanel>
            <TabPanel>
              <div className="side-nav-filters-tab-wrapper">
                <div className="side-nav-content-wrapper">
                  {getRangeFilterBlock(
                    "search.perTerm",
                    4000,
                    20000,
                    100,
                    [
                      setRangeOrDefault("feePerTermMin", 4000),
                      setRangeOrDefault("feePerTermMax", 20000),
                    ],
                    onChangeFeePerTerm
                  )}
                  {getRangeFilterBlock(
                    "search.deposit",
                    0,
                    20000,
                    100,
                    [
                      setRangeOrDefault("depositMin", 0),
                      setRangeOrDefault("depositMax", 20000),
                    ],
                    onChangeDeposit
                  )}
                  <div className="school-data-section">
                    <div className="availablity-wrapper d-flex justify-content-between f-ac">
                      <div className="school-data-section-title">
                        {t("search.scholarshipsAvailability")}
                      </div>
                      <Switch
                        checked={
                          props.filterState?.scholarshipsAvailable || false
                        }
                        onClick={handleSwitchChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel>
              <div className="side-nav-filters-tab-wrapper">
                {getSubjectsTabContent()}
              </div>
            </TabPanel>
          </Tabs>
        </div>

        <div className="fixed-side-footer f-ac">
          <div className="publishing-text curs-P" onClick={clearFilters}>
            {t("search.clearFilters")}
          </div>
          <button type="submit" className="next-button f-ac f-jc">
            {t("search.showSchools")}
          </button>
        </div>
      </form>
    </div>
  )

  //------SELECTION TEMPLATE FUNCTION
  function getSelectionFilterBlock(filterProperty, title) {
    return (
      <div className="school-data-section">
        <div className="school-data-section-title">{t(title)}</div>
        <div className="data-checkboxes-list w-100">
          {props.filterState[filterProperty]?.slice(0, 4).map((el, index) => {
            return (
              <div key={index} className="data-checkbox-item">
                <input
                  type="checkbox"
                  className="custom-checkbox"
                  id={`black_checkbox14 ${el.id}`}
                  value={el.value}
                  checked={el.isChecked || false}
                  onChange={item =>
                    onChangeSchoolSelectionProperty(item, filterProperty)
                  }
                />
                <label htmlFor={`black_checkbox14 ${el.id}`}>{el.value}</label>
              </div>
            )
          })}
        </div>
        {props.filterState[filterProperty]?.slice(4).length !== 0 && (
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <div className={`accordion-title`}>{t("search.expandList")}</div>
            </AccordionSummary>
            <AccordionDetails className={"p-0"}>
              <div className="data-checkboxes-list">
                {props.filterState[filterProperty]
                  ?.slice(4)
                  .map((el, index) => {
                    return (
                      <div key={index} className="data-checkbox-item">
                        <input
                          type="checkbox"
                          className="custom-checkbox"
                          id={`black_checkbox14 ${el.id}`}
                          value={el.value}
                          checked={el.isChecked || false}
                          onChange={item =>
                            onChangeSchoolSelectionProperty(
                              item,
                              filterProperty
                            )
                          }
                        />
                        <label htmlFor={`black_checkbox14 ${el.id}`}>
                          {el.value}
                        </label>
                      </div>
                    )
                  })}
              </div>
            </AccordionDetails>
          </Accordion>
        )}
      </div>
    )
  }

  //------RANGE TEMPLATE FUNCTION
  function getRangeFilterBlock(title, min, max, step, defaultRange, onChange) {
    return (
      <div className="school-data-section">
        <div className="school-data-section-title">{t(title)}</div>
        <Range
          value={defaultRange.map(el => Number(el))}
          min={Number(min)}
          step={Number(step)}
          max={Number(max)}
          onChange={el => onChange(el, min, max)}
        />
      </div>
    )
  }

  //------SUBJECTS TEMPLATE FUNCTION
  function getSubjectsTabContent() {
    return (
      <div className="side-nav-content-wrapper subjects-filters-tab">
        <div className="school-data-section-title">{t("search.subjects")}</div>
        <div className="school-data-section">
          {props.filterState?.subjects.length === 0 && (
            <div className="add-subj-first f-ac">
              <PlusIcon
                className="some-icon-plus curs-P"
                onClick={handleAddClick}
              />
              <div
                className="user-nav-bar-option active curs-P"
                onClick={handleAddClick}
              >
                {t("search.addSubject")}
              </div>
            </div>
          )}
          {props.filterState?.subjects?.map((subj, i) => {
            return (
              <div key={i}>
                <div className="school-data-section school-type-section">
                  <div className="selections-wrapper f-js f-ac">
                    <div className="search-select-types-wrapper w-100">
                      <div id="select-types" className="custom-select-wrapper">
                        <div className="custom-select">
                          <div>
                            <select
                              id="categoryId"
                              name="subjectName"
                              onChange={el => handleInputChangeSubjects(el, i)}
                              className="contactSelect select-type-filter"
                              value={subj.subjectName}
                            >
                              <option>{t("main.header.subjects")}</option>
                              {props.filterState.subjectsList.map(item => {
                                return (
                                  <option key={item.id} value={item.value}>
                                    {item.value}
                                  </option>
                                )
                              })}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="add-child-wrapper f-js f-ac">
                    {props.filterState.subjects.length - 1 === i && (
                      <>
                        <PlusIcon
                          className="plus-icon curs-P"
                          onClick={handleAddClick}
                        />
                        <div
                          className="user-nav-bar-option active curs-P"
                          onClick={handleAddClick}
                        >
                          {t("search.addSubject")}
                        </div>
                      </>
                    )}
                    {props.filterState.subjects.length - 1 === i && (
                      <div
                        className="user-nav-bar-option active curs-P"
                        onClick={() => handleRemoveClick(i)}
                      >
                        {t("search.removeSubject")}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}

export default MoreFilters
