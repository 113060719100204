import React, { useEffect, useState } from "react"
import SearchResultsHeaderSection from "../../sections/search_results_sections/SearchResultsHeaderSection"
import SearchResultsMapContentSection from "../../sections/search_results_sections/SearchResultsMapContentSection"
import MoreFilters from "../../sections/search_results_sections/MoreFilters"
import SideNavMenuSection from "../../sections/SideNavMenuSection"
import UserSideNavSection from "../../sections/login_signup_side_navs/UserSideNavSection"
import SchoolSideNavSection from "../../sections/login_signup_side_navs/SchoolSideNavSection"
import axios from "../../http/axios"
import { useTranslation } from "react-i18next"
import Layout from "../../components/Layout"
import { SCHOOL_MODES } from "../../constants/schoolModes"
import { isBrowser } from "../../components/CurrentUserContext"

const SearchResultsMapPage = () => {
  const [schoolMode] = useState(
    isBrowser()
      ? new URLSearchParams(window.location.search).get("schoolMode") || 0
      : 0
  )
  const [queryParams] = useState(
    isBrowser()
      ? new URLSearchParams(window.location.search)
      : new URLSearchParams("")
  )

  const { t, i18n } = useTranslation()
  const currLang = i18n.language
  const [resultArray, setResultArray] = useState([])
  const [mainPageFiltersRender] = useState(1)

  const [filterState, setFilterState] = useState(getInitialFilterState())
  function getInitialFilterState() {
    return {
      name: null,
      schoolTypes: [],
      languageCode: currLang,
      educationalProgrammes: [],
      studentAgeMin: "",
      studentAgeMax: "",
      boardersAgeMin: "",
      boardersAgeMax: "",
      specializations: [],
      religions: [],
      locations: [],
      settlementTypes: [],
      transportStations: [],
      studentsAmountMin: "",
      studentsAmountMax: "",
      boardersPercentMin: "",
      boardersPercentMax: "",
      internationalStudentsPercentMin: "",
      internationalStudentsPercentMax: "",
      teacherToStudentsRatioMin: "",
      teacherToStudentsRatioMax: "",
      teacherToStudentsRatio: "",
      feePerTermMin: "",
      feePerTermMax: "",
      depositMin: "",
      depositMax: "",
      scholarshipsAvailable: false,
      subjectsList: [],
      subjects: [],
      regions: [],
    }
  }

  function updateFilterState(fs) {
    setFilterState(fs)
  }

  function setSelectedParamsFromQuery(
    property,
    data,
    selectedDataLine,
    temporaryFilterState
  ) {
    if (selectedDataLine) {
      let selectedDataList = selectedDataLine.split(",").map(el => el.trim())
      data.forEach(el => {
        el.isChecked = selectedDataList.includes(el.value)
      })
    }
    temporaryFilterState[property] = data
  }

  function setSchoolFilterParametersFromUrl(temporaryFilterState) {
    temporaryFilterState.schoolTypes = getArrayDataFromQueryParams(
      "schoolTypes"
    )
    temporaryFilterState.educationalProgrammes = getArrayDataFromQueryParams(
      "educationalProgrammes"
    )
    temporaryFilterState.settlementTypes = getArrayDataFromQueryParams(
      "settlementTypes"
    )
    temporaryFilterState.transportStations = getArrayDataFromQueryParams(
      "transportStations"
    )
    temporaryFilterState.religions = getArrayDataFromQueryParams("religions")
    temporaryFilterState.regions = getArrayDataFromQueryParams("regions")
    temporaryFilterState.subjects = getArrayDataFromQueryParams(
      "subjects",
      boxSubjectValueObject
    )

    temporaryFilterState.name = queryParams.get("searchInput")
      ? queryParams.get("searchInput")
      : null

    temporaryFilterState.studentAgeMin = getNumberDataFromQueryParams(
      "studentAgeMin"
    )
    temporaryFilterState.studentAgeMax = getNumberDataFromQueryParams(
      "studentAgeMax"
    )
    temporaryFilterState.boardersAgeMin = getNumberDataFromQueryParams(
      "boardersAgeMin"
    )
    temporaryFilterState.boardersAgeMax = getNumberDataFromQueryParams(
      "boardersAgeMax"
    )
    temporaryFilterState.studentsAmountMin = getNumberDataFromQueryParams(
      "studentsAmountMin"
    )
    temporaryFilterState.studentsAmountMax = getNumberDataFromQueryParams(
      "studentsAmountMax"
    )
    temporaryFilterState.boardersPercentMin = getNumberDataFromQueryParams(
      "boardersPercentMin"
    )
    temporaryFilterState.boardersPercentMax = getNumberDataFromQueryParams(
      "boardersPercentMax"
    )
    temporaryFilterState.internationalStudentsPercentMin = getNumberDataFromQueryParams(
      "internationalStudentsPercentMin"
    )
    temporaryFilterState.internationalStudentsPercentMax = getNumberDataFromQueryParams(
      "internationalStudentsPercentMax"
    )
    temporaryFilterState.teacherToStudentsRatioMin = getNumberDataFromQueryParams(
      "teacherToStudentsRatioMin"
    )
    temporaryFilterState.teacherToStudentsRatioMax = getNumberDataFromQueryParams(
      "teacherToStudentsRatioMax"
    )
    temporaryFilterState.teacherToStudentsRatio = getNumberDataFromQueryParams(
      "teacherToStudentsRatio"
    )
    temporaryFilterState.feePerTermMin = getNumberDataFromQueryParams(
      "feePerTermMin"
    )
    temporaryFilterState.feePerTermMax = getNumberDataFromQueryParams(
      "feePerTermMax"
    )
    temporaryFilterState.depositMin = getNumberDataFromQueryParams("depositMin")
    temporaryFilterState.depositMax = getNumberDataFromQueryParams("depositMax")
    temporaryFilterState.scholarshipsAvailable = getBoolDataFromQueryParams(
      "scholarshipsAvailable"
    )
  }

  function setSummerSchoolFilterParametersFromUrl(temporaryFilterState) {
    temporaryFilterState.schoolOrganisationTypes = getArrayDataFromQueryParams(
      "schoolOrganisationTypes"
    )
    temporaryFilterState.educationalProgrammes = getArrayDataFromQueryParams(
      "educationalProgrammes"
    )
    temporaryFilterState.settlementTypes = getArrayDataFromQueryParams(
      "locationTypes"
    )
    temporaryFilterState.name = queryParams.get("searchInput")
      ? queryParams.get("searchInput")
      : null

    temporaryFilterState.studentAgeMin = getNumberDataFromQueryParams(
      "studentAgeMin"
    )
    temporaryFilterState.studentAgeMax = getNumberDataFromQueryParams(
      "studentAgeMax"
    )
  }

  useEffect(async () => {
    let temporaryFilterState = { ...filterState }

    if (Number(schoolMode) === SCHOOL_MODES.SUMMER_SCHOOL) {
      setSummerSchoolFilterParametersFromUrl(temporaryFilterState)

      axios
        .post(
          "/summer-schools",
          buildSummerSchoolsFilterBody(temporaryFilterState)
        )
        .then(res => {
          setResultArray(res.data)
        })

      await axios
        .get(
          `/lookups/schoolOrganisationTypes/lookupresult?lang=${i18n.language}`
        )
        .then(res =>
          setSelectedParamsFromQuery(
            "schoolOrganisationTypes",
            res.data,
            queryParams.get("schoolOrganisationTypes"),
            temporaryFilterState
          )
        )

      await axios
        .get(
          `/lookups/summerSchoolEducationalPrograms/lookupresult?lang=${i18n.language}`
        )
        .then(res =>
          setSelectedParamsFromQuery(
            "educationalProgrammes",
            res.data,
            queryParams.get("educationalProgrammes"),
            temporaryFilterState
          )
        )
    } else {
      setSchoolFilterParametersFromUrl(temporaryFilterState)
      axios
        .post("/schools", buildSchoolsFilterBody(temporaryFilterState))
        .then(res => {
          setResultArray(res.data)
        })

      await axios
        .get(`/transport-stations/lookupresult?lang=${i18n.language}`)
        .then(res =>
          setSelectedParamsFromQuery(
            "transportStations",
            res.data,
            queryParams.get("transportStations"),
            temporaryFilterState
          )
        )

      await axios
        .get(`/lookups/religions/lookupresult?lang=${i18n.language}`)
        .then(res =>
          setSelectedParamsFromQuery(
            "religions",
            res.data,
            queryParams.get("religions"),
            temporaryFilterState
          )
        )

      await axios
        .get(`/lookups/schoolSubjects/lookupresult?lang=${i18n.language}`)
        .then(res => {
          let subjectsFromQuery = queryParams.get("subjects")
          if (subjectsFromQuery && subjectsFromQuery?.toString().trim()) {
            temporaryFilterState.subjects = subjectsFromQuery
              .split(",")
              .map(el => boxSubjectValueObject(el))
          }

          temporaryFilterState.subjectsList = res.data
        })

      await axios
        .get(`/lookups/regions/lookupresult?lang=${i18n.language}`)
        .then(res =>
          setSelectedParamsFromQuery(
            "regions",
            res.data,
            queryParams.get("regions"),
            temporaryFilterState
          )
        )

      await axios
        .get(`/lookups/schoolTypes/lookupresult?lang=${i18n.language}`)
        .then(res =>
          setSelectedParamsFromQuery(
            "schoolTypes",
            res.data,
            queryParams.get("schoolTypes"),
            temporaryFilterState
          )
        )

      await axios
        .get(`/educational-programs/lookup?lang=${i18n.language}`)
        .then(res =>
          setSelectedParamsFromQuery(
            "educationalProgrammes",
            res.data,
            queryParams.get("educationalProgrammes"),
            temporaryFilterState
          )
        )

      await axios
        .get(`/lookups/settlementTypes/lookupresult?lang=${i18n.language}`)
        .then(res =>
          setSelectedParamsFromQuery(
            "settlementTypes",
            res.data,
            queryParams.get("settlementTypes"),
            temporaryFilterState
          )
        )
    }
    setFilterState(temporaryFilterState)
  }, [mainPageFiltersRender, queryParams])

  function getArrayDataFromQueryParams(
    paramName,
    customValueObjectBoxFunction = boxStringToValueObject
  ) {
    let data = queryParams
      .get(paramName)
      ?.split(",")
      .map(el => el.trim())
      .filter(el => el != null && el !== "")
    return data ? data.map(el => customValueObjectBoxFunction(el)) : []
  }

  function getNumberDataFromQueryParams(paramName) {
    let data = queryParams.get(paramName)?.toString().trim()
    return data && isNumber(data) ? data : null
  }

  function getBoolDataFromQueryParams(paramName) {
    let data = queryParams.get(paramName)?.toString().trim()
    return data === true.toString()
  }

  function isNumber(n) {
    return !isNaN(parseFloat(n)) && !isNaN(n - 0)
  }

  function boxStringToValueObject(el) {
    return {
      value: el.trim(),
      isChecked: true,
    }
  }

  function boxSubjectValueObject(el) {
    return {
      subjectName: el.trim(),
    }
  }

  const clearFilters = () => {
    let filterToClear = {
      ...filterState,
      name: null,
      studentAgeMin: "",
      studentAgeMax: "",
      boardersAgeMin: "",
      boardersAgeMax: "",
      studentsAmountMin: "",
      studentsAmountMax: "",
      boardersPercentMin: "",
      boardersPercentMax: "",
      internationalStudentsPercentMin: "",
      internationalStudentsPercentMax: "",
      teacherToStudentsRatioMin: "",
      teacherToStudentsRatioMax: "",
      teacherToStudentsRatio: "",
      feePerTermMin: "",
      feePerTermMax: "",
      depositMin: "",
      depositMax: "",
      scholarshipsAvailable: false,
      subjects: [],
    }

    clearArraySelectionProperty(filterToClear, "schoolTypes")
    clearArraySelectionProperty(filterToClear, "educationalProgrammes")
    clearArraySelectionProperty(filterToClear, "specializations")
    clearArraySelectionProperty(filterToClear, "religions")
    clearArraySelectionProperty(filterToClear, "settlementTypes")
    clearArraySelectionProperty(filterToClear, "transportStations")
    clearArraySelectionProperty(filterToClear, "regions")

    if (isBrowser()) {
      window.history.pushState(
        {},
        document.title,
        window.location.pathname +
          (Number(schoolMode) === SCHOOL_MODES.SCHOOL
            ? buildSchoolsUrlParamsStringBaseOnFilter(filterToClear)
            : buildSummerSchoolsUrlParamsStringBaseOnFilter(filterToClear))
      )
    }

    setFilterState(filterToClear)
    applyFilteredSchoolsList(filterToClear)
  }

  function clearArraySelectionProperty(filterToClear, property) {
    filterToClear[property] = filterToClear[property]?.map(el => {
      return { ...el, isChecked: false }
    })
  }

  const applyFilteredSchoolsList = (fs = null) => {
    if (Number(schoolMode) === SCHOOL_MODES.SUMMER_SCHOOL) {
      filterSummerSchools(fs)
    } else if (Number(schoolMode) === SCHOOL_MODES.SCHOOL) {
      filterSchools(fs)
    }

    let curFilterState = fs ? fs : filterState

    if (isBrowser()) {
      window.history.pushState(
        {},
        document.title,
        window.location.pathname +
          (Number(schoolMode) === SCHOOL_MODES.SCHOOL
            ? buildSchoolsUrlParamsStringBaseOnFilter(curFilterState)
            : buildSummerSchoolsUrlParamsStringBaseOnFilter(curFilterState))
      )
    }
  }

  function filterSummerSchools(fs = null) {
    axios
      .post(
        "/summer-schools",
        buildSummerSchoolsFilterBody(fs ? fs : filterState)
      )
      .then(res => {
        setResultArray(res.data)
      })
  }

  function buildSummerSchoolsFilterBody(fs) {
    return {
      languageCode: currLang,
      name: fs.name ? fs.name : null,

      studentAgeMin: setPrimitivePropertyFilterValue(fs, "studentAgeMin"),
      studentAgeMax: setPrimitivePropertyFilterValue(fs, "studentAgeMax"),

      schoolOrganisationTypes: setSelectionPropertyFilterValue(
        fs,
        "schoolOrganisationTypes"
      ),
      educationalProgrammes: setSelectionPropertyFilterValue(
        fs,
        "educationalProgrammes"
      ),
    }
  }

  function filterSchools(fs = null) {
    axios
      .post("/schools", buildSchoolsFilterBody(fs ? fs : filterState))
      .then(res => {
        setResultArray(res.data)
      })
  }

  function setPrimitivePropertyFilterValue(fs, propertyName) {
    return fs[propertyName] != null && fs[propertyName] !== ""
      ? fs[propertyName]
      : undefined
  }

  function setSelectionPropertyFilterValue(fs, propertyName) {
    return fs[propertyName] != null && fs[propertyName].length !== 0
      ? fs[propertyName].filter(el => el.isChecked).map(el => el.value)
      : undefined
  }

  function configureStudentsAmountRangeMax(fs) {
    return fs.studentsAmountMax === 2000 ? null : fs.studentsAmountMax
  }

  function buildSchoolsFilterBody(fs) {
    return {
      languageCode: currLang,

      name: fs.name ? fs.name : null,
      studentAgeMin: setPrimitivePropertyFilterValue(fs, "studentAgeMin"),
      studentAgeMax: setPrimitivePropertyFilterValue(fs, "studentAgeMax"),

      boardersAgeMin: setPrimitivePropertyFilterValue(fs, "boardersAgeMin"),
      boardersAgeMax: setPrimitivePropertyFilterValue(fs, "boardersAgeMax"),

      studentsAmountMin: setPrimitivePropertyFilterValue(
        fs,
        "studentsAmountMin"
      ),
      studentsAmountMax:
        fs.studentsAmountMax !== ""
          ? configureStudentsAmountRangeMax(fs)
          : undefined,

      boardersPercentMin: setPrimitivePropertyFilterValue(
        fs,
        "boardersPercentMin"
      ),
      boardersPercentMax: setPrimitivePropertyFilterValue(
        fs,
        "boardersPercentMax"
      ),

      internationalStudentsPercentMin: setPrimitivePropertyFilterValue(
        fs,
        "internationalStudentsPercentMin"
      ),
      internationalStudentsPercentMax: setPrimitivePropertyFilterValue(
        fs,
        "internationalStudentsPercentMax"
      ),

      teacherToStudentsRatioMin: setPrimitivePropertyFilterValue(
        fs,
        "teacherToStudentsRatioMin"
      ),
      teacherToStudentsRatioMax: setPrimitivePropertyFilterValue(
        fs,
        "teacherToStudentsRatioMax"
      ),

      feePerTermMin: setPrimitivePropertyFilterValue(fs, "feePerTermMin"),
      feePerTermMax: setPrimitivePropertyFilterValue(fs, "feePerTermMax"),

      depositMin: setPrimitivePropertyFilterValue(fs, "depositMin"),
      depositMax: setPrimitivePropertyFilterValue(fs, "depositMax"),

      teacherToStudentsRatio: setPrimitivePropertyFilterValue(
        fs,
        "teacherToStudentsRatio"
      ),

      transportStations: setSelectionPropertyFilterValue(
        fs,
        "transportStations"
      ),
      religions: setSelectionPropertyFilterValue(fs, "religions"),
      specializations: setSelectionPropertyFilterValue(fs, "specializations"),
      schoolTypes: setSelectionPropertyFilterValue(fs, "schoolTypes"),
      educationalProgrammes: setSelectionPropertyFilterValue(
        fs,
        "educationalProgrammes"
      ),
      settlementTypes: setSelectionPropertyFilterValue(fs, "settlementTypes"),
      regions: setSelectionPropertyFilterValue(fs, "regions"),

      subjects:
        fs.subjects != null && fs.subjects.length !== 0
          ? fs.subjects.map(el => {
              return {
                subjectName: el.subjectName,
              }
            })
          : undefined,

      scholarshipsAvailable: fs.scholarshipsAvailable === true ? true : null,
    }
  }

  function buildSchoolsUrlParamsStringBaseOnFilter(fs) {
    const paramsMap = new Map()
    paramsMap.set("schoolMode", schoolMode)

    if (fs.name) {
      paramsMap.set("searchInput", name)
    }

    convertPrimitivePropertyToQueryParam(fs, paramsMap, "studentAgeMin")
    convertPrimitivePropertyToQueryParam(fs, paramsMap, "studentAgeMax")
    convertPrimitivePropertyToQueryParam(fs, paramsMap, "boardersAgeMin")
    convertPrimitivePropertyToQueryParam(fs, paramsMap, "boardersAgeMax")
    convertPrimitivePropertyToQueryParam(fs, paramsMap, "studentsAmountMin")
    convertPrimitivePropertyToQueryParam(fs, paramsMap, "studentsAmountMax")
    convertPrimitivePropertyToQueryParam(fs, paramsMap, "boardersPercentMin")
    convertPrimitivePropertyToQueryParam(fs, paramsMap, "boardersPercentMax")
    convertPrimitivePropertyToQueryParam(
      fs,
      paramsMap,
      "internationalStudentsPercentMin"
    )
    convertPrimitivePropertyToQueryParam(
      fs,
      paramsMap,
      "internationalStudentsPercentMax"
    )
    convertPrimitivePropertyToQueryParam(
      fs,
      paramsMap,
      "teacherToStudentsRatioMin"
    )
    convertPrimitivePropertyToQueryParam(
      fs,
      paramsMap,
      "teacherToStudentsRatioMax"
    )
    convertPrimitivePropertyToQueryParam(fs, paramsMap, "feePerTermMin")
    convertPrimitivePropertyToQueryParam(fs, paramsMap, "feePerTermMax")
    convertPrimitivePropertyToQueryParam(fs, paramsMap, "depositMin")
    convertPrimitivePropertyToQueryParam(fs, paramsMap, "depositMax")
    convertPrimitivePropertyToQueryParam(
      fs,
      paramsMap,
      "teacherToStudentsRatio"
    )

    convertSelectionPropertyToQueryParam(fs, paramsMap, "transportStations")
    convertSelectionPropertyToQueryParam(fs, paramsMap, "religions")
    convertSelectionPropertyToQueryParam(fs, paramsMap, "specializations")
    convertSelectionPropertyToQueryParam(fs, paramsMap, "schoolTypes")
    convertSelectionPropertyToQueryParam(fs, paramsMap, "educationalProgrammes")
    convertSelectionPropertyToQueryParam(fs, paramsMap, "settlementTypes")
    convertSelectionPropertyToQueryParam(fs, paramsMap, "regions")

    if (fs.subjects && fs.subjects.length) {
      let selectedSubjects = fs.subjects.map(el => el.subjectName)
      paramsMap.set("subjects", selectedSubjects.join(","))
    }

    if (fs.scholarshipsAvailable) {
      paramsMap.set("scholarshipsAvailable", fs.scholarshipsAvailable)
    }

    return (
      (paramsMap.size ? "?" : "") +
      (paramsMap.size
        ? Array.from(
            paramsMap,
            ([key, value]) =>
              `${key}=${encodeURIComponent(value?.toString().trim())}`
          ).join("&")
        : "")
    )
  }

  function buildSummerSchoolsUrlParamsStringBaseOnFilter(fs) {
    const paramsMap = new Map()
    paramsMap.set("schoolMode", schoolMode)

    if (fs.name) {
      paramsMap.set("searchInput", name)
    }

    convertPrimitivePropertyToQueryParam(fs, paramsMap, "studentAgeMin")
    convertPrimitivePropertyToQueryParam(fs, paramsMap, "studentAgeMax")

    convertSelectionPropertyToQueryParam(
      fs,
      paramsMap,
      "schoolOrganisationTypes"
    )
    convertSelectionPropertyToQueryParam(fs, paramsMap, "educationalProgrammes")

    return (
      (paramsMap.size ? "?" : "") +
      (paramsMap.size
        ? Array.from(
            paramsMap,
            ([key, value]) =>
              `${key}=${encodeURIComponent(value?.toString().trim())}`
          ).join("&")
        : "")
    )
  }

  function convertPrimitivePropertyToQueryParam(fs, paramsMap, propertyName) {
    if (
      fs[propertyName] !== null &&
      fs[propertyName]?.toString()?.trim() !== ""
    ) {
      paramsMap.set(propertyName, fs[propertyName])
    }
  }

  function convertSelectionPropertyToQueryParam(fs, paramsMap, propertyName) {
    if (
      fs[propertyName] &&
      fs[propertyName].filter(el => el.isChecked).length
    ) {
      let selectedProperties = fs[propertyName]
        .filter(el => el.isChecked)
        .map(el => el.value)
      paramsMap.set(propertyName, selectedProperties.join(","))
    }
  }

  return (
    <Layout
      wrapperClass={"user-saved search-results"}
      pageTitle={`${t("main.header.schools")} | Smart Admissions Service`}
      isSecondLayout={true}
    >
      <SearchResultsHeaderSection />
      <SearchResultsMapContentSection
        items={resultArray}
        filterSchools={applyFilteredSchoolsList}
        filterState={filterState}
        updateFilterState={updateFilterState}
        schoolMode={schoolMode}
      />

      <MoreFilters
        filterSchools={applyFilteredSchoolsList}
        filterState={filterState}
        updateFilterState={updateFilterState}
        clearFilters={clearFilters}
      />

      <SideNavMenuSection />
      <UserSideNavSection />
      <SchoolSideNavSection />
    </Layout>
  )
}

export default SearchResultsMapPage
