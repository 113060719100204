import React from "react"

import { openNav } from "../../scripts/sideNavMenu.js"

import MenuIconDark from "../../svg/menu-left-black.inline.svg"

import { Link } from "gatsby"
import ChangeChoiceLanguage from "../../components/ChangeChoiceLanguage"
import UserLoginName from "../UserLoginName"
import { useTranslation } from "react-i18next"

const SearchResultsHeaderSection = () => {
  const { t, i18n } = useTranslation()
  const lang = i18n.language
  return (
    <div className="header position-fixed shrinked container py-4 px-5">
      <div className="row d-flex justify-content-between">
        <div className="logo-menu-block f-ac f-js col-md-5 col-lg-3">
          {/* <!---- LOGO ----> */}
          <Link to={`/${lang}/`}>
            <div className="logo_full mR-20">
              <div className="logo_full_block logo_full_block_1">Smart</div>
              <div className="logo_full_block logo_full_block_2">
                Admissions
              </div>
              <div className="logo_full_block logo_full_block_3">Service</div>
              <div className="logo_shrinked_block">
                <pre>S A S</pre>
              </div>
            </div>
          </Link>
          {/* <!---- MENU BUTTON ----> */}
          <div className="icon_button menu" onClick={openNav}>
            <MenuIconDark
              className={`menu_icon shrinked-menu-icon dark_menu`}
            />
            <div className="menu_title active right">
              {t("school.header.menu")}
            </div>
          </div>
        </div>

        <div className="col login-panel f-ac f-je">
          <UserLoginName pathName={"dark_menu"} />
          <ChangeChoiceLanguage />
        </div>


      </div>
    </div>
  )
}

export default SearchResultsHeaderSection
